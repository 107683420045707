import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { Box, css } from "@mui/system";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { Grid, Grow, Typography, useTheme } from "@mui/material";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { LinkButton } from "StaticPages/Components/Commons/LinkButton";
import { ServiceImageCollectionItem } from "StaticPages/Components/Commons/ServiceImageCollectionItem";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("CG ＆ BIM | VISUALIZ INC")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return <>
        <main>
            <PageSummary
                title={t("CG＆BIM制作事業")}
                description={<>
                    {t("BIMデータがもたらすインパクトは")}<br />
                    {t("建築プレゼンだけには収まらない。")}<br />
                    {t("広がる情景は息を呑む世界観。")}<br />
                    {t("現実とデジタルの境界は無いに等しい。")}
                </>}
                subTitle={t("CG ＆ BIM")}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/CgBim_top.jpg").default}
            />

            <Box
                sx={{
                    background: theme.palette.background.default,
                }}
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("BIMデータからCG制作\nそしてプロセス管理へ")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("建築にとってBIMデータがどれほどの可能性を秘めているか。様々な産業が生産プロセスをデジタル化する事で、効率を上げています。建築にとって数%のコスト削減はそのまま全て利益です。DX移行による最大の恩恵を受けるのが建築とも言われ、多くの企業が移行を行っています。")}<br />
                        {t("しかしながらBIMへの移行は簡単な道程ではありません。")}<br />
                        {t("私たちはBIM移行に伴う作業をお手伝いし、貴社にとって最善のプロセスを提供致します。")}
                    </SectionText1>

                    <Box
                        mx="auto"
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            maxWidth: "1080px",
                        }}
                        p={{ xs: 1, sm: 1, md: 1.5, lg: 2 }}
                        pt={0}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    p={{ xs: 1, sm: 1, md: 1.5, lg: 2 }}
                                >
                                    <ServiceImageCollectionItem
                                        title={t("CAD")}
                                        src={"/images/cgbimtile1.jpg"}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    p={{
                                        xs: 1,
                                        sm: 1,
                                        md: 1.5,
                                        lg: 2
                                    }}
                                >
                                    <ServiceImageCollectionItem
                                        title={t("CG")}
                                        src={"/images/cgbimtile2.jpg"}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    p={{
                                        xs: 1,
                                        sm: 1,
                                        md: 1.5,
                                        lg: 2
                                    }}
                                >
                                    <ServiceImageCollectionItem
                                        title={t("HoloLens")}
                                        src={"/images/cgbimtile3.jpg"}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <SectionText1>
                        {t("業務効率化による時間削減と手戻りの防止でパーセントレベルでのコストを削減するには建築プロセスのDX化を行う事が大きな効果を発揮します。")}<br />
                        {t("BIMを通じ、一度デジタルに落とし込まれた情報は、CGだけでなく平面図管理・立面図管理・スケジュール管理・現実との融合に加え、離れている関係者同士の情報がシームレスに融合します。")}<br />
                        {t("打ち合わせや図面確認といった日々の業務から物理的なハードルを取り除き、誰もが何処に居ても繋がる社会はもう直ぐそこに来ています。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                mx="auto"
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("私たちが4つのステップを\nお手伝いします")}
                />

                <Typography>
                    {t("これからの競争に打ち勝つために必要なのは建築のDX化。")}<br />
                    {t("大手しか出来ないことを誰にでも出来るようにするのが私たちの仕事です。")}
                </Typography>

            </Box>

            <Box>

                <StepCollectionItem
                    title={t("CAD to BIM")}
                    step={t("STEP1")}
                    detail={<>
                        {t("御社のCADデータからBIMデータを作成。")}<br />
                        {t("施設構造からファミリー、一般建築まで。")}<br />
                    </>}
                    src="/images/tilestep1.jpg"
                />

                <StepCollectionItem
                    title={t("BIM to CG")}
                    step={t("STEP2")}
                    detail={<>
                        {t("BIMデータからCGアプリを作成。")}<br />
                        {t("映像だけでなくアバターが内部を動き回れるアプリも")}<br />
                        {t("専用でご提供できます。")}
                    </>}
                    src="/images/tilestep2.jpg"
                />

                <StepCollectionItem
                    title={t("CG to MR")}
                    step={t("STEP3")}
                    detail={<>
                        {t("CGアプリからMRデータを作成。")}<br />
                        {t("現実とシンクロするバーチャルデータで現場管理を実現。")}<br />
                    </>}
                    src="/images/tilestep3.jpg"
                />

                <StepCollectionItem
                    title={t("Progress Management")}
                    step={t("STEP4")}
                    detail={<>
                        {t("MRとBIMの融合でほぼ管理の全てを。")}<br />
                        {t("現場と本社を結び、ダイレクトな進捗管理を実現します。")}<br />
                    </>}
                    src="/images/tilestep4.jpg"
                />
            </Box>

            <Box
                textAlign="center"
                mx="auto"
                mt={12}
                py={8}
                bgcolor={theme.palette.background.default}
            >
                <Box maxWidth="900px" mx="auto">
                    <Typography variant="h5" fontFamily="Jost">CG SAMPLE</Typography>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box p={{ xs: 2 }}>
                                    <TransitionImage
                                        src="/images/bgbimsample1.jpg"
                                        alt=""
                                    />
                                    <Box>
                                        <Typography>{t("2021.5月  OKAYAMA")}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box p={{ xs: 2 }}>
                                    <TransitionImage
                                        src="/images/bgbimsample2.jpg"
                                        alt=""
                                    />
                                    <Box>
                                        <Typography>{t("2021.5月  OKAYAMA MIRRORWORLD")}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box p={{ xs: 2 }}>
                                    <TransitionImage
                                        src="/images/bgbimsample3.jpg"
                                        alt=""
                                    />
                                    <Box>
                                        <Typography>{t("2021.5月  Client：ARAKIGUMI")}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box px={3} maxWidth="400px" py={12} mx="auto">
                <LinkButton
                    text={t("お申し込み・お問い合わせ")}
                    href="/contact"
                />
            </Box>
        </main>
    </>;
};

const StepCollectionItem = (
    {
        title,
        step,
        src,
        detail,
    }: {
        src: string,
        title: string,
        detail: ReactNode,
        step: string,
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once scrollStartOffset={"10%"}>
            {state =>
                <Box
                    my={4}
                    sx={{
                        width: "100%"
                    }}
                    px={{ xs: 1, sm: 2, md: 3 }}
                    py={{ xs: 2, sm: 0 }}
                    textAlign="center"
                >
                    <Grid container
                        sx={{
                            width: "100%",
                            maxWidth: "780px",
                            margin: "0 auto"
                        }}>
                        <Grid
                            item
                            xs={12}
                        >
                            <Box pl={4}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontFamily: "Jost",
                                        textAlign: "left"
                                    }}
                                >{step}</Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                px={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ width: "100%", height: "100%" }}
                            >
                                <FadeAndSlideAnimation
                                    in={state === "entered"}
                                    transform={{
                                        rotate: "0deg",
                                        translate: {
                                            x: "0px",
                                            y: "0px",
                                        },
                                        scale: 0.92,
                                    }}
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <TransitionImage
                                        src={src}
                                        alt={title}
                                        css={css`
                                            height: 100%;
                                        `}
                                    />
                                </FadeAndSlideAnimation>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                p={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    height: "100%"
                                }}
                                textAlign="left"
                                display="flex"
                                flexDirection="column"
                            >
                                <Box mt="auto">
                                    <SkewAnimation
                                        in={state === "entered"}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            sx={{
                                                fontFamily: "Jost"
                                            }}
                                        >{title}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mb="auto"
                                    mt={{
                                        xs: 0.5,
                                        sm: 1,
                                    }}
                                >
                                    <SkewAnimation
                                        in={state === "entered"}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >{detail}</Typography>
                                    </SkewAnimation>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </ScrollTrigger>
    );
};
